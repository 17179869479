import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import styles from "./webview.module.scss";
import { useLocation, useNavigate } from "react-router-dom";

const WebView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search); // Parses the query string
  const externalUrl = searchParams.get('url');

  return (
    <>
      <section className={`${styles.webView}`}>


        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className={`${styles.cardCstm} py-2 cardCstm`}>
                <div className="px-2 mb-2">

                  <Button onClick={() => navigate("/task")} className="border-0 p-0" variant="transparent">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 20" fill="none">
                      <path d="M2.91436 9.76907L12.1877 2.01034C12.4158 1.81953 12.4158 1.5135 12.1877 1.32268C11.9596 1.13186 11.5939 1.13186 11.3658 1.32268L1.67935 9.42704C1.45128 9.61785 1.45128 9.92388 1.67935 10.1147L11.3658 18.2155C11.4777 18.3091 11.6283 18.3595 11.7746 18.3595C11.9209 18.3595 12.0715 18.3127 12.1834 18.2155C12.4115 18.0246 12.4115 17.7186 12.1834 17.5278L2.91436 9.76907Z" fill="white" stroke="white" stroke-width="2.26051" />
                    </svg>
                  </Button>
                </div>
                <div style={{ height: '100vh', width: '100%' }}>
                  {externalUrl ? (
                    <iframe
                      src={externalUrl}
                      style={{ width: '100%', height: '100%', border: 'none' }}
                      title="WebView"
                    />
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default WebView;
