/* Instruments */
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as URL from "../../../helper/url_helper";
import { get } from "../../../helper/api_helper";
import { generateSignature, showToast } from "../../../helper/common";
import { authSlice } from "../auth/slice";
import { socket } from "../../../utils/socket";
import { getValuesSocketData } from "../../../helper/secretManager";


export const getSettings = createAsyncThunk(
  "getSettings",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData(true);
      const response = await get(URL.GET_SETTINGS, { signature, nonce });
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      if (response.status === "failure") {
        showToast(response?.message, "error");
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response?.data);
      return response.data;

    } catch (error) {
      console.log(error, "<===error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);



