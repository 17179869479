import React, { createContext, useState, useContext, useEffect } from 'react';
import { gameArray } from '../../helper/common';
import { useDispatch, useSelector } from 'react-redux';
import { getBitanicaBtncBalanceApi } from '../../lib/store/actions';
import { Howl } from 'howler';
// import startRound from "../../assets/sound/startround.mp3";
// import claim from "../../assets/sound/claim.mp3";
// import bombendgame from "../../assets/sound/bombendgame.mp3";
// import diamondevenline from "../../assets/sound/diamondevenline.mp3";
// import diamondoddline from "../../assets/sound/diamondoddline.mp3";

// Create the Game Context
const GameContext = createContext();

const sounds = {
    startround: "https://minehunt-live.s3.us-east-2.amazonaws.com/startround.mp3",
    claim: "https://minehunt-live.s3.us-east-2.amazonaws.com/claim.mp3",
    bombendgame: "https://minehunt-live.s3.us-east-2.amazonaws.com/bombendgame.mp3",
    diamondevenline: "https://minehunt-live.s3.us-east-2.amazonaws.com/diamondevenline.mp3",
    diamondoddline: "https://minehunt-live.s3.us-east-2.amazonaws.com/diamondoddline.mp3",
};

// Preload sounds using Howler
const preloadSounds = (soundFiles, callback) => {
    let soundsToLoad = Object.keys(soundFiles).length;

    Object.entries(soundFiles).forEach(([key, src]) => {
        const sound = new Howl({
            src: [src],
            preload: true,
            onload: () => {
                soundsToLoad -= 1;
                if (soundsToLoad === 0 && callback) callback();
            },
            onloaderror: (id, err) => {
                console.error(`Error loading sound "${key}":`, err);
                soundsToLoad -= 1;
                if (soundsToLoad === 0 && callback) callback();
            },
        });

        soundFiles[key] = sound; // Replace file path with Howl instance
    });
};

// GameProvider Component
export const GameProvider = ({ children }) => {
    const [game, setGame] = useState({
        ...gameArray,
        gameData: gameArray.gameData.reverse(),
        lineData: gameArray.lineData.reverse(),
    });
    const [isGameStart, setGameStart] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [gameLoading, setGameLoading] = useState(true);
    const { user: userData } = useSelector(({ Auth }) => Auth);
    const [gameEnergyLeft, setGameEnergyLeft] = useState(userData?.energyBalance || 0);
    const [btncBalance, setBtncBalance] = useState(userData?.btncBalance || 0);
    const [audioLoad, setAudioLoad] = useState(false);

    const dispatch = useDispatch();

    const resetGame = async () => {
        setGame({
            ...gameArray,
            gameData: gameArray.gameData.reverse(),
            lineData: gameArray.lineData.reverse(),
        });
    };

    useEffect(() => {
        if (userData) {
            setGameEnergyLeft(userData.energyBalance || 0);
            setBtncBalance(userData.btncBalance || 0);
        }
    }, [userData]);

    const SyncBitanicaBalance = async () => {
        dispatch(
            getBitanicaBtncBalanceApi({
                callBack: (err, data) => {
                    if (!err) setBtncBalance(data?.balance);
                },
            })
        );
    };

    // Preload sounds on mount
    useEffect(() => {
        preloadSounds(sounds, () => {
            console.log('All sounds preloaded.');
            setAudioLoad(true);
        });
    }, []);

    const playSound = (soundKey) => {
        if (userData?.enabledSound && sounds[soundKey]) {
            sounds[soundKey].stop(); // Reset the sound
            sounds[soundKey].play(); // Play the sound
        }
    };

    return (
        <GameContext.Provider
            value={{
                gameLoading,
                setGameLoading,
                game,
                setGame,
                isGameStart,
                isLoading,
                setGameStart,
                setIsLoading,
                resetGame,
                gameEnergyLeft,
                setGameEnergyLeft,
                btncBalance,
                setBtncBalance,
                SyncBitanicaBalance,
                audioLoad,
                playSound,
            }}
        >
            {children}
        </GameContext.Provider>
    );
};

// Custom Hook to use the Game Context
export const useGameContext = () => {
    const context = useContext(GameContext);
    if (!context) {
        throw new Error('useGameContext must be used within a GameProvider');
    }
    return context;
};
