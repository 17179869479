import Login from "./AuthPages/Login";
import Game from "./MainPages/Game";
import GameHistory from "./MainPages/GameHistory";
import LeaderBoard from "./MainPages/Leaderboard";
import Roadmap from "./MainPages/RoadMap";
import TaskList from "./MainPages/TaskList";
import WebView from "./MainPages/Webview";
import Wallet from "./MainPages/wallet";

export const routes = [
  { path: "/login", component: <Login /> },
];

export const privateRoutes = [
  { path: "/game", component: <Game /> },
  { path: "/task", component: <TaskList /> },
  { path: "/wallet", component: <Wallet /> },
  { path: "/game-history", component: <GameHistory /> },
  { path: "/leaderboard", component: <LeaderBoard /> },
  { path: "/roadmap", component: <Roadmap /> },
  { path: "/webview", component: <WebView /> },

];
