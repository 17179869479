import { createAsyncThunk } from "@reduxjs/toolkit"
import * as URL from "../../../helper/url_helper";
import { get, post, put } from "../../../helper/api_helper";
import { generateSignature, showToast } from "../../../helper/common";
import { authSlice } from "../auth/slice";
import { socket } from "../../../utils/socket";
import { getValuesSocketData } from "../../../helper/secretManager";

export const tasksListApi = createAsyncThunk(
  "tasksListApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let response = await post(URL.TASK_LIST, data, { signature, nonce })
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      if (response.status === "failure") {
        showToast(response?.message, "error");
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)
      response.filter = data;
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      showToast(error?.message || "Something went wrong!", "error");
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)


export const completeTaskApi = createAsyncThunk(
  "completeTaskApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let response = await put(URL.TASK_COMPLETE + data?._id, data, { signature, nonce })
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      if (response.status === "failure") {
        showToast(response?.message, "error");
        return Thunk.rejectWithValue(response?.message)
      }
      showToast(response?.message, "success");
      callBack && callBack(null, response)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      showToast(error?.message || "Something went wrong!", "error");
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)

//roadmaps

export const getRoadmapsListApi = createAsyncThunk(
  "getRoadmapsListApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let response = await post(URL.GET_ROADMAPS_LIST, data, { signature, nonce })
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      if (response.status === "failure") {
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)

