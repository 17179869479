import { Providers } from "./lib/providers";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { privateRoutes, routes } from "./pages";
import "./i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettings, telegramBotAutoLogin, userSession } from "./lib/store/actions";
import PrivateRoute from "./components/PrivateRoute";
import "./App.css";
import MainLayout from "./components/Layout/Mainlayout";
import { useNavigate } from "react-router-dom";
import { ToastProvider } from "./context/CustomToast";
import GameEnterRoute from "./components/GameEnterRoute";
import { useIsTelegramWebApp } from "./hooks/useTelegram";
import { GameProvider, useGameContext } from "./context/GameContext";
import { decrypt } from "./helper/secretManager";
import Maintenance from "./pages/Maintenance";
import { isMobile, preloadSounds } from "./helper/common";
import { authSlice } from "./lib/store/auth/slice";
import { socket } from "./utils/socket";



function App() {
  console.log = function () { };  // Override console.log to a no-op function
  return (
    <ToastProvider>
      <Providers>
        <ToastContainer
          // transition={"Slide"}
          position="top-center"
          hideProgressBar={true}
          closeButton={false}
          limit={1}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        // autoClose={1500}
        />
        <GameProvider >
          <BrowserRouter>
            <RoutesList />
          </BrowserRouter>
        </GameProvider>
      </Providers>
    </ToastProvider >
  );
}

const RoutesList = () => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(({ Auth }) => ({
    user: Auth.user
  }));
  const [sessionLoader, setSessionLoader] = useState(true);
  const [loginLoader, setLoginLoader] = useState(true);
  const { setGame, setGameStart, setGameLoading, gameLoading } = useGameContext();
  const maxRetries = 5; // Maximum number of retries
  var retryCount = 0;

  const isTelegramWebApp = useIsTelegramWebApp();
  const userId = user?._id;


  useEffect(() => {
    setLoginLoader(true);
    dispatch(getSettings({
      callBack: (err, data) => {
        if (err) {
          return;
        };
        if (data?.maintenance?.status) {
          setTimeout(() => {
            setLoginLoader(false);
          }, 2000)
          navigate("/maintenance");
        } else {
          // setTimeout(() => {
          //   setLoginLoader(false);
          // }, 2000)
          telegramWebAppLogin();
        }
      }
    }));
  }, [isTelegramWebApp]);

  const telegramWebAppLogin = useCallback(() => {

    if (retryCount > maxRetries && !isMobile()) {
      // Stop retries if we've exceeded the maxRetries
      setTimeout(() => {
        setLoginLoader(false);
      }, 6000)
      return;
    }

    try {
      if (isTelegramWebApp && window.Telegram.WebApp.initDataUnsafe?.user) {
        const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
        setLoginLoader(true); // Show loader while trying to log in
        dispatch(authSlice.actions.setLoginBotQrLoader({ type: "botLogin" }));
        dispatch(telegramBotAutoLogin({
          data: {
            telegram_id: telegramUser.id,
            telegram_username: telegramUser?.username,
            first_name: telegramUser?.first_name,
            last_name: telegramUser?.last_name
          },
          callBack: async (err, data) => {
            if (parseInt(err?.status_code) === 406) {
              setLoginLoader(false);
              return navigate("/maintenance");
            }
            if (err) return; //if login error then exit retrying
            // setTimeout(() => {
            setLoginLoader(false);
            // }, 4000)
            await ativeGameDetail(data);
            navigate("/game");
          }
        }));

      }
      else {
        // No user data received, retry login
        retryCount += 1;
        // setLoginLoader(false);
        setTimeout(() => {
          telegramWebAppLogin(); // Retry
        }, 1000); // Retry after 2 seconds
      }
    } catch (err) {
      console.error("Error during Telegram WebApp login", err);
      // setTimeout(() => {
      //   setLoginLoader(false);
      // }, 5000)
    }

  }, [isTelegramWebApp]);

  useEffect(() => {
    // const handleVisibilityChange = () => {
    //   // Check if the window.Telegram.WebApp exists (i.e., page is opened inside Telegram)
    //   if (isTelegramWebApp && window.Telegram?.WebApp) {
    //     const tele = window.Telegram.WebApp; // Initialize the TELEGRAM BOT
    //     if (document.hidden) {
    //       // App is minimized or out of focus
    //       return tele.close(); // Close or minimize the mini-app if needed
    //     }
    //   }
    // }
    if (isTelegramWebApp && window.Telegram?.WebApp) {
      const tele = window.Telegram.WebApp; // Initialize the TELEGRAM BOT
      tele.expand(); // Expands the app on the users' phone to 100% height
    }
    // document.addEventListener('visibilitychange', handleVisibilityChange);

    // return () => {
    //   document.removeEventListener('visibilitychange', handleVisibilityChange);
    // };

  }, [isTelegramWebApp]);


  const checkAuth = async () => {
    try {
      setSessionLoader(true);
      await dispatch(userSession({
        callBack: (err, userData) => {
          if (err) {
            setTimeout(() => {
              setSessionLoader(false);
            }, 2000)
            if (!["/login", "/"].includes(window.location.pathname)) {
              document.cookie = "";
              setTimeout(() => {
                navigate("/login");
              }, 2000)
            }
          } else {
            ativeGameDetail(userData);
            setTimeout(() => {
              setSessionLoader(false);
            }, 2000)
          }
        }
      })); // Dispatch the action to check authentication
    } catch (error) {
      setTimeout(() => {
        setSessionLoader(false);
        setGameLoading(false);
      }, 2000)
    } finally {
      setTimeout(() => {
        setSessionLoader(false);
      }, 2000)
      // setGameLoading(false);
    }
  };

  useEffect(() => {
    const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;

    if (!userId && !isTelegramWebApp && !telegramUser) {
      setTimeout(() => {
        checkAuth();
      }, 300);
    } else {
      setTimeout(() => {
        setSessionLoader(false);
      }, 2000)
    }

  }, [userId, isTelegramWebApp]);


  const ativeGameDetail = async (userData) => {
    return new Promise(async (resolve, reject) => {
      try {

        if ((userData?._id && !userData?.currentGame?._id) || !userData?._id || !userData?.currentGame?._id) {
          // setTimeout(() => {
          //   setGameLoading(false);
          //   resolve();  // Resolve since no game to load
          // }, 2000);
          setTimeout(() => {
            setGameLoading(false);
            resolve();  // Resolve since no game to load
          }, 200);
          return;
        }

        setGameLoading(true);
        if (userData?.currentGame?._id) {
          let updatedData = { ...userData?.currentGame, gameData: [], lineData: [] };
          // const keys = await socket.emit('getValue', { data: {} });

          // let decoded = updatedData?.gameData
          //   ? await decrypt(userData?.currentGame?.gameData, keys?.ENCRYPT_SECRET_KEY, keys?.ENCRYPT_SECRET_IV)
          //   : [];

          // decoded = decoded ? JSON.parse(decoded) : [];

          updatedData = {
            ...updatedData,
            gameData: userData?.currentGame?.gameData,
            lineData: userData?.currentGame?.lineData,
            winnings: updatedData?.winnings?.length
              ? [...updatedData.winnings]
              : [],
          };

          setGame({
            ...updatedData,
            gameData: updatedData?.gameData?.reverse(),
            lineData: updatedData?.lineData?.reverse()
          });
          resolve(updatedData);
          setGameLoading(false);
          setGameStart(true);

        } else {
          setGameLoading(false);
          resolve();  // Resolve if no game data
        }
      } catch (err) {
        setGameLoading(false);
        reject(err);  // Reject if there is an exception
      }
    });
  };



  useEffect(() => {
    // Disable scrolling on the body
    document.body.style.overflow = "hidden";

    // Function to handle touchmove event
    const preventScroll = (event) => {
      // Check if the event's target is within a scrollable element
      const isScrollable = event.target.closest('.allow-scroll');
      const noMini = event.target.closest('.no-minimize')
      if (!isScrollable || noMini) {
        // Prevent scrolling if not within a scrollable element
        event.preventDefault();
      }
    };

    // Add touch event listeners to the document
    document.addEventListener('touchmove', preventScroll, { passive: false });

    return () => {
      // Cleanup on component unmount
      document.body.style.overflow = "";
      document.removeEventListener('touchmove', preventScroll);
    };
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     dispatch(checkBitanicaLinkApi({}));
  //   }
  // }, [user])

  return (
    <Routes>
      <Route
        exact={true}
        path={"/maintenance"}
        element=
        {<Maintenance
          sessionLoader={sessionLoader}
          loginLoader={loginLoader}
          gameLoader={gameLoading}
        />}
      />
      <Route element={""}>
        <Route index element={<Navigate to="/login" />} />
        {routes.map((data, index) => (
          <Route
            // onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element={
              <GameEnterRoute
                sessionLoader={sessionLoader}
                loginLoader={loginLoader}
                gameLoader={gameLoading}
              >
                {data.component}
              </GameEnterRoute>
            }
            key={index}
          />
        ))}
      </Route>
      <Route element={<MainLayout />}>
        <Route index element={<Navigate to="/game" />} />
        {privateRoutes.map((data, index) => (
          <Route
            // onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element=
            {<PrivateRoute
              sessionLoader={sessionLoader}
              loginLoader={loginLoader}
              gameLoader={gameLoading}
            >
              {data.component}
            </PrivateRoute>}
            key={index}
          />
        ))}
      </Route>
    </Routes>
  )
}
export default App;
